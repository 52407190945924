import { Component, OnInit } from '@angular/core';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-malaysia-purpose',
  templateUrl: './malaysia-purpose.component.html',
  styleUrls: ['./malaysia-purpose.component.scss']
})
export class MalaysiaPurposeComponent implements OnInit {

 
  malaysiaTravelPurpose:any
  selectvisatypes:String='';
  toCountry:String='';
  tocountry:any;
  fromCountry:String='';
  fromcountry:any;
  recordsize:any = 10;
  perPageitem:any = 10;
  page:any;
  travelpurpose:any;
  travelPurpose:any;
  country:any;
  Id:any;
  popup:boolean = false;
  popup1:boolean = false;
  travelPurposeEdit:any;
  editTravelpurpose:any;
  popup2:any;

  constructor(private authservice: AuthService) { }

  ngOnInit() {
    this.authservice.getMalaysiaTravellingPriceList().subscribe((data)=>{
      this.malaysiaTravelPurpose=data;
    });
    this.authservice.getFromCountryList().subscribe(data=>{
      this.fromcountry= data;
    })
  }
  
  findByFromCountry(){
    let data={
      "fromCountry" : this.fromCountry,
      // "selectvisaType" : this.selectvisatypes,
      "recordSize": this.recordsize
    }
    let requestdata = new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.getMalaysiaSearchFromCountry(requestdata).subscribe(res=>{
      console.log(res);
        var arrayKey = Object.values(res);
        console.log(arrayKey);
        this.malaysiaTravelPurpose = arrayKey[0];
        this.perPageitem = this.recordsize;
    }, error => console.log(error));
  }

  deletePurpose(){
    this.authservice.deleteMalaysiaPurpose(this.Id).subscribe(data=>{
      this.fromcountry= data;
      window.location.reload();
    })
  }

  addTravelPurpose(){
    let data={
      // "toCountry": this.toCountry,
      "fromCountry" : this.fromCountry,
      "selectvisaType" : this.selectvisatypes,
      "travelPurpose": this.travelPurpose
    }
    let requestdata = new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.addMalaysiaTravelPurpose(requestdata).subscribe(data=>{
      this.travelpurpose= data;
      this.popup = false;
      window.location.reload();
    })
  }

  Openeditpopup(id:any,to_country:any){
    this.Id = id;
    this.country = to_country;
    this.popup1 = true;
  }

  OpenDeletePopup(id:any){
    this.Id= id;
    this.popup2 = true;
  }

  EditTravelPurpose(travelPurposeEdit:any){
    let data={
      "id":this.Id,
      // "toCountry": this.country,
      "travelPurpose": travelPurposeEdit
    }
    let requestdata = new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.editMalaysiaTravelPurpose(requestdata).subscribe(data=>{
      this.editTravelpurpose= data;
      this.popup1 = false;
      window.location.reload();
    })
  }

  selectVisaType = [
    {name: "Single-Entry"},
    {name: "Multiple-Entry"},
  ]

  ToCountry = [
    {name: 'India'},
    {name: 'Turkey'},
    {name: 'Pakistan'},
    {name: 'Thailand'},
    {name: 'United-Arab-Emirates'},
    {name: 'Malasyia'},
    {name: 'Egypt'}
  ]

}
