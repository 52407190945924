import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-egypt-travelling-price',
  templateUrl: './egypt-travelling-price.component.html',
  styleUrls: ['./egypt-travelling-price.component.scss']
})
export class EgyptTravellingPriceComponent implements OnInit {

  egyptPriceList:any;
  fromCountry:String = '';
  toCountry:String = '';
  selectvisatypes:String='';
  fromcountry:any;
  tocountry:any;
  recordsize:any=10;
  perpageItem:any=10;
  egyptInsuransePrice:any;

  constructor(private authservice: AuthService, private route:ActivatedRoute )  {
  }

  ngOnInit() {
    this.authservice.getEgyptTravellingPriceList().subscribe(data=> {
      this.egyptPriceList = data;
    });

    this.authservice.getFromCountryList().subscribe(data=>{
      this.fromcountry= data;
    })
  }

  findByFromCountry(){
    let data={
      // "toCountry": this.toCountry,
      "from_country" : this.fromCountry,
      "visa_types" : this.selectvisatypes,
      "recordsize": this.recordsize
    }
    let requestdata = new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.getEgyptSearchFromCountry(requestdata).subscribe(res=>{
      console.log(res);
        this.egyptPriceList = res;
        this.perpageItem = this.recordsize;
    }, error => console.log(error));
  }

  updatePrice(id:string,newPrice:any){
    let data ={
      "id" : id,
      "newPrice" : newPrice,
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.updateEgyptTravellingprice(requestdata)
    .subscribe(data => {
      this.egyptInsuransePrice = data;
      
    }, error => console.log(error));
  }

  selectVisaType = [
    {name: "Single Entry"},
    {name: "Multiple Entry"},
  ]


}
