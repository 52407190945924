import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-view-travel-insurance-details',
  templateUrl: './view-travel-insurance-details.component.html',
  styleUrls: ['./view-travel-insurance-details.component.scss']
})
export class ViewTravelInsuranceDetailsComponent implements OnInit {

  id:any;
  fileSize:any;
  userid:any;
  Payment:any;
  spinnerLoader:boolean = false;
  PaymentDetails:any;
  insuranceList:any;
  insurancePayment:any;
  insuranceTravel:any;
  InsuranceDetails: boolean = true ;
  InsurancePaymentDetails: boolean = false;
  TravelInsuranceDetails: boolean = false;
  popup2: boolean = false;
  validationCheck:any;
  status:boolean = false;
  message:any;
  deleteStatus: boolean;
  AditionalError:any = "No Data Found For This Applicant....";
  statusClass = 'active';
  statusClass1 = 'not-active';
  statusClass2 = 'not-active';
  statusClassNav = 'not-active';
  statusClassNav1 = 'not-active';
  statusClassNav2 = 'not-active';
  statusClassNav3 = 'not-active';
  statusClassNav4 = 'not-active';
  statusClassNav5 = 'not-active';
  statusClassNav6 = 'not-active';
  statusClassNav7 = 'not-active';
  statusClassNav8 = 'not-active';
  statusClassNav9 = 'not-active';
  statusClassNav10 = 'not-active';
  statusClassNav11 = 'not-active';
  statusClassNav12 = 'not-active';
  statusClassNav13 = 'not-active';
  statusClassNav14 = 'not-active';
  statusClassNav15 = 'not-active';
  statusClassNav16 = 'not-active';
  statusClassNav17 = 'not-active';
  statusClassNav18 = 'not-active';
  statusClassNav19 = 'not-active';
  statusClassNav20 = 'not-active';
  isDisabled:boolean;
  filee:any;
  file:any;
  path:any;
  document:any;
  CheckFileName:any;
  checkfile:any;
  dateFile:any;
  DounloadEVisaFile:any;
  fileExtension:any;
  encodedImage4:any;
  arrayValue:any;
  FirstName:any;
  email:any;
  sendMissingDocuments:any;
  filepath:any;
  date:any;
  getfilepath:any;
  fileId:any;
  adminVisibleId:any;
  
  constructor(public authService: AuthService, private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    this.adminVisibleId = sessionStorage.getItem("adminID");
    this.id = this.route.snapshot.params['id'];
    this.userid = this.route.snapshot.params['userId'];
    this.authService.getEdocInsuranceDetails(this.id).subscribe(res => {
      this.insuranceList = res;
      this.FirstName = this.insuranceList.firstName;
      this.email = this.insuranceList.email;
      var dateFormate = this.insuranceList.dateOfApplication;
      var dateOnly = dateFormate.split("T");
      this.date = dateOnly[0];
      console.log(this.FirstName);
      if(this.insuranceList.payment == true){
        this.Payment = 'Paid';
      }else{
        this.Payment = 'Unpaid';
      }
    });

    this.authService.getEdocInsurancePaymentDetails(this.id).subscribe(res => {
      this.insurancePayment = res;
      if(this.insurancePayment.paymentStatus == true){
        this.PaymentDetails = 'Paid';
      }else{
        this.PaymentDetails = 'Unpaid';
      }
    });

    this.authService.getInsuranceCheckFileName(this.id)
      .subscribe(data => {
        this.CheckFileName = data;
        this.checkfile = this.CheckFileName.Data.name;
        this.dateFile = this.CheckFileName.Data.date;
        this.fileId = this.CheckFileName.Data.pkid;
      }, error => console.log(error));

      this.authService.getDownloadEVisaInsuranceFile(this.id)
      .subscribe(data => {
        this.DounloadEVisaFile = data;
        var arrayKey = Object.keys(data);
        console.log(arrayKey);
        this.filepath = arrayKey;
        this.encodedImage4 = arrayKey;
        this.arrayValue = Object.values(data);
        this.fileExtension = this.arrayValue;
        
      }, error => console.log(error));
  }

  showMyApplicationPage(){
    this.router.navigate(['account']);
  }

  showMyCustomerPage(){
    this.router.navigate(['customer_view']);
  }

  showMyTravelInsurance(){
    this.router.navigate(['travel_insurance']);
  }

  gotoDeleteFile(){
    this.popup2 = true;

  }

  showtravelPurposePage(){
    this.router.navigate(['turkey_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showtravelPricePage(){
    this.router.navigate(['turkey_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showtravelInsurancePage(){
    this.router.navigate(['turkey_insurance']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showEgyptPricePage(){
    this.router.navigate(['egypt_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showPakistanPricePage(){
    this.router.navigate(['pakistan_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showUAEPricePage(){
    this.router.navigate(['uae_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showUAEPurposePage(){
    this.router.navigate(['uae_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }
  
  showThailandPricePage(){
    this.router.navigate(['thailand_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showThailandPurposePage(){
    this.router.navigate(['thailand_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showMalaysiaPurposePage(){
    this.router.navigate(['malaysia_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';

  }

  showIndiaPricePage(){
    this.router.navigate(['india_price']);
    this.statusClassNav = 'active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showIndiaPurposePage(){
    this.router.navigate(['india_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showMalaysiaPricePage(){
    this.router.navigate(['malaysia_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showPakistanPurposePage(){
    this.router.navigate(['pakistan_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showInsuranceDetails(){
    this.InsuranceDetails = true;
    this.InsurancePaymentDetails = false;
    this.TravelInsuranceDetails = false;
    if(this.statusClass = 'active'){
      this.statusClass1 = 'not-active';
      this.statusClass2 = 'not-active';
    }
  }

  showInsurancePaymentDetails(){
    this.InsuranceDetails = false;
    this.InsurancePaymentDetails = true;
    this.TravelInsuranceDetails = false;
    if(this.statusClass1 = 'active'){
      this.statusClass = 'not-active';
      this.statusClass2 = 'not-active';
    }
  }

  showTravelInsuranceDetails(){
    this.InsuranceDetails = false;
    this.InsurancePaymentDetails = false;
    this.TravelInsuranceDetails = true;
    if(this.statusClass2 = 'active'){
      this.statusClass = 'not-active';
      this.statusClass1 = 'not-active';
    }
  }

  deletePurpose(){
    console.log(this.filepath);
    let data ={
      "id":this.fileId,
      "filepath" : this.filepath[0],
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authService.gotoDeleteInsuranceFile(requestdata)
      .subscribe(data => {
        this.getfilepath = data;
        this.popup2 = false;
        this.deleteStatus = false;
        this.CheckFileName.Status= this.deleteStatus;
      }, error => console.log(error));
  }

  handleChange(files: FileList) {
    if (files && files.length) {
      this.filee = files[0].name;
    }
      this.file = files[0];
      let data ={
        "evisaInsuranceId":this.id,
      }
      if (!this.validateFile(files[0].name)) {
        console.log('Selected file format is not supported');
        this.validationCheck = "Selected file format is not supported (Please Select Only PNG and PDF) and File less than 2 MB.";
        return false;
      }
      if( !this.validateFileSize()){
        this.validationCheck = "Selected file format is not supported (Please Select Only PNG and PDF) and File less than 2 MB.";
        return false;
      }
      this.spinnerLoader = true;
      let requestdata = new FormData();
      requestdata.append('file', this.file);
      requestdata.append('user',JSON.stringify(data));
      this.authService.storeInsuranceUploaddocuments(requestdata).subscribe(data=>{
        console.log(data);
        this.path = data;
        if (Response) {
          this.spinnerLoader = false;
        }
        this.document= this.path.status;
        console.log(this.document);
        window.location.reload();
      },
      error =>{
        console.log(error);
        window.location.reload();
      });
      this.isDisabled= true;
    }

    
    validateFile(name: String) {
      var ext = name.substring(name.lastIndexOf('.') + 1);
      if (ext.toLowerCase() == 'png') {
          return true;
      // }else if(ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg'){
      //     return true;
      // }else if(ext.toLowerCase() == 'tiff'){
      //     return true;
      }else if(ext.toLowerCase() == 'pdf'){
          return true;
      }
      else {
          return false;
      }
    }

    validateFileSize(){
      if (this.file) {
        let fileSize = 0;
       if (this.file.size <= 1024 * 1024 || this.file.size <= 1024){
         this.fileSize = (Math.round(this.file.size * 100 / (1024 * 1024)) / 100).toString() + 'MB';
         this.fileSize = (Math.round(this.file.size * 100 / 1024) / 100).toString() + 'KB';
         return true;
       }
       else{
        return false;
       }
      }
    }
    gotoSendEvisaInsuranceDownloadDocument(){
      let data ={
        "firstName" : this.FirstName,
        "insuranceID" : this.id,
        "userID" : this.userid,
        "email" : this.email,
        "fileName" :this.checkfile,
      }
      let requestdata=new FormData();
      requestdata.append('user',JSON.stringify(data));
      this.authService.sendEvisaInsuranceDownloadDocuments(requestdata)
      .subscribe(
        data => {
          console.log(data);
          this.sendMissingDocuments = data;
          this.status= true;
          this.message = "Succefully sent document on your email...!";
        },
        error => console.log(error));
    }

    gotoProcessPagelink(){
      window.open('https://www.covidinsurance4turkey.com/Home');
    }

}
