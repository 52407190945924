import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountComponent } from './account/account.component';
import { CustomerViewComponent } from './customer-view/customer-view.component';
import { EgyptTravellingPriceComponent } from './egypt-travelling-price/egypt-travelling-price.component';
import { IndiaTravellingPriceComponent } from './india-travelling-price/india-travelling-price.component';
import { IndiaVisaTravelPurposeComponent } from './india-visa-travel-purpose/india-visa-travel-purpose.component';
import { LoginComponent } from './login/login.component';
import { MalaysiaPriceComponent } from './malaysia-price/malaysia-price.component';
import { MalaysiaPurposeComponent } from './malaysia-purpose/malaysia-purpose.component';
import { PakistanPriceComponent } from './pakistan-price/pakistan-price.component';
import { PakistanPurposeComponent } from './pakistan-purpose/pakistan-purpose.component';
import { ProcessPageComponent } from './process-page/process-page.component';
import { ThailandPriceComponent } from './thailand-price/thailand-price.component';
import { ThailandPurposeComponent } from './thailand-purpose/thailand-purpose.component';
import { TravelInsuranceComponent } from './travel-insurance/travel-insurance.component';
import { TurkeyTravelingPriceComponent } from './turkey-traveling-price/turkey-traveling-price.component';
import { TurkeyTravellingInsuranceComponent } from './turkey-travelling-insurance/turkey-travelling-insurance.component';
import { TurkeyVisaTravelPurposeComponent } from './turkey-visa-travel-purpose/turkey-visa-travel-purpose.component';
import { UnitedArabEmiratesPriceComponent } from './united-arab-emirates-price/united-arab-emirates-price.component';
import { UnitedArabEmiratesPurposeComponent } from './united-arab-emirates-purpose/united-arab-emirates-purpose.component';
import { ViewApplicationComponent } from './view-application/view-application.component';
import { ViewTravelInsuranceDetailsComponent } from './view-travel-insurance-details/view-travel-insurance-details.component';
import { ViewTravelInsuranceComponent } from './view-travel-insurance/view-travel-insurance.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'login', 
        component: LoginComponent 
    },
    {
        path: 'customer_view', 
        component: CustomerViewComponent 
    },
    // {
    //     path: 'charts',
    //     loadChildren: () =>
    //         import('modules/charts/charts-routing.module').then(m => m.ChartsRoutingModule),
    // },
    // {
    //     path: 'dashboard',
    //     loadChildren: () =>
    //         import('modules/dashboard/dashboard-routing.module').then(
    //             m => m.DashboardRoutingModule
    //         ),
    // },
    // {
    //     path: 'auth',
    //     loadChildren: () =>
    //         import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    // },
    // {
    //     path: 'error',
    //     loadChildren: () =>
    //         import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    // },
    // {
    //     path: 'tables',
    //     loadChildren: () =>
    //         import('modules/tables/tables-routing.module').then(m => m.TablesRoutingModule),
    // },
    // {
    //     path: 'version',
    //     loadChildren: () =>
    //         import('modules/utility/utility-routing.module').then(m => m.UtilityRoutingModule),
    // },
    { 
        path: 'account', 
             component: AccountComponent 
    },
    { 
        path: 'accountEdit/:pkid/:userId', 
             component: AccountEditComponent 
    },
    { 
        path: 'travel_insurance', 
             component: TravelInsuranceComponent 
    },
    { 
        path: 'india_price', 
             component: IndiaTravellingPriceComponent 
    },
    { 
        path: 'india_purpose', 
             component: IndiaVisaTravelPurposeComponent 
    },
    { 
        path: 'viewInsurance/:id/:userId', 
             component: ViewTravelInsuranceComponent 
    },
    { 
        path: 'viewInsuranceDetails/:id/:userId', 
             component: ViewTravelInsuranceDetailsComponent 
    },
    { 
        path: 'viewApplication/:pkid/:userId', 
             component: ViewApplicationComponent 
    },
    {
        path: 'process_page/:pkid/:userId', 
        component: ProcessPageComponent 
    },
    {
        path: 'turkey_price', 
        component: TurkeyTravelingPriceComponent 
    },
    {
        path: 'turkey_purpose', 
        component: TurkeyVisaTravelPurposeComponent 
    },
    {
        path: 'turkey_insurance', 
        component: TurkeyTravellingInsuranceComponent 
    },
    {
        path: 'egypt_price', 
        component: EgyptTravellingPriceComponent 
    },
    {
        path: 'malaysia_price', 
        component: MalaysiaPriceComponent 
    },
    {
        path: 'malaysia_purpose', 
        component: MalaysiaPurposeComponent 
    },
    {
        path: 'pakistan_price', 
        component: PakistanPriceComponent 
    },
    {
        path: 'pakistan_purpose', 
        component: PakistanPurposeComponent 
    },
    {
        path: 'uae_price', 
        component: UnitedArabEmiratesPriceComponent 
    },
    {
        path: 'uae_purpose', 
        component: UnitedArabEmiratesPurposeComponent 
    },
    {
        path: 'thailand_price', 
        component: ThailandPriceComponent 
    },
    {
        path: 'thailand_purpose', 
        component: ThailandPurposeComponent 
    },
    // {
    //     path: '**',
    //     pathMatch: 'full',
    //     loadChildren: () =>
    //         import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    // },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}