import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Signup } from '@modules/auth/models/Signup';
import { AuthService } from '@modules/auth/services';

@Component({
    selector: 'sb-login',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './login.component.html',
    styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
  id: any;
  registerUser:any;
  submitted = false;
  registerFormRe: FormGroup;
  adminVisibleId:any;
  public sessionStorage = sessionStorage;

    constructor(private registerServiceService: AuthService,
        private router: Router,private formBuilder: FormBuilder) {}
    ngOnInit() {

    this.registerUser = new Signup();
    this.registerFormRe = this.formBuilder.group({
      userEmail: ['', [Validators.required,Validators.email]],
      userPassword: ['',[Validators.required]],
     });
    }

    save() {
        let data ={
          "userEmail" : this.registerFormRe.value.userEmail,
          "userPassword" : this.registerFormRe.value.userPassword,

        }
        // this.registerServiceService.createEmployee(data)
        let requestdata=new FormData();
        // requestdata.append('file',this.files);
        // requestdata.append('user',JSON.stringify(data));
        this.registerServiceService.createEmployee(data)
        .subscribe(
          data => {
            // console.log(data);
            this.registerUser = data;
            if(this.registerUser.status == true){
              this.adminVisibleId = this.registerUser.data.isAdminVisible;
              sessionStorage.setItem("adminID", this.adminVisibleId);
              this.router.navigate(['travel_insurance']);
            }else if(this.registerUser.message == "User not found"){
              alert("User not found");
            }else if(this.registerUser.status == false){
              alert("Please Verify your Email and Password!");
            }
          },
          error => console.log(error));
      }
    
      get fval() {
        return this.registerFormRe.controls;
        }
    
      onSubmit() {
        this.submitted = true;
        if(this.registerFormRe.invalid){
          return;
        }
        this.save();    
      }
    
}