import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-india-travelling-price',
  templateUrl: './india-travelling-price.component.html',
  styleUrls: ['./india-travelling-price.component.scss']
})
export class IndiaTravellingPriceComponent implements OnInit {

  
  indiaPriceList:any;
  fromCountry:String = '';
  toCountry:String = '';
  selectvisatypes:String='';
  fromcountry:any;
  tocountry:any;
  recordsize:any=10;
  perpageItem:any=10;
  constructor(private authservice: AuthService, private route:ActivatedRoute )  {
  }

  ngOnInit() {
    this.authservice.getIndiaTravellingPriceList().subscribe(data=> {
      this.indiaPriceList = data;
    });

    this.authservice.getFromCountryList().subscribe(data=>{
      this.fromcountry= data;
    })
  }

  findByFromCountry(){
    let data={
      // "toCountry": this.toCountry,
      "fromCountry" : this.fromCountry,
      "selectvisaType" : this.selectvisatypes,
      "recordSize": this.recordsize
    }
    let requestdata = new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.getSearchIndiaFromCountry(requestdata).subscribe(res=>{
      console.log(res);
        var arrayKey = Object.values(res);
        console.log(arrayKey);
        this.indiaPriceList = arrayKey[0];
        this.perpageItem = this.recordsize;
    }, error => console.log(error));
  }

  updatePrice(id:string,newPrice:any){
    this.authservice.updateIndiaTravellingprice(id,newPrice).subscribe((responce)=> {
      console.log("Responce:", responce)
    });
  }

  selectVisaType = [
    // {name: "Single-Entry"},
    // {name: "Multiple-Entry"},
    {name: "E-Emergency X-Misc"},
    {name: "eTourist Visa"},
    {name: "eMEDICAL VISA"},
    {name: "eBUSINESS VISA"},
    {name: "eCONFERENCE Visa"},
    {name: "eMEDICAL ATTENDANT VISA"},
  ]

  // ToCountry = [
  //   {name: 'India'},
  //   {name: 'Turkey'},
  //   {name: 'Pakistan'},
  //   {name: 'Thailand'},
  //   {name: 'United-Arab-Emirates'},
  //   {name: 'Malasyia'},
  //   {name: 'Egypt'}
  // ]
}
