import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { viewApplication } from '@app/models/view_application';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-process-page',
  templateUrl: './process-page.component.html',
  styleUrls: ['./process-page.component.scss']
})
export class ProcessPageComponent implements OnInit {
  id:any;
viewapplication:any;
additionalApplication:any;
uploadApplication:any;
paymentApplication:any;
CommunicationApplication:any;
userid:any;
UploadFileNameApplication:any;
thumbnail:any;
ResidenceProofthumbnail:any;
BiographicalPassportthumbnail:any;
Photographythumbnail:any;
binaryString:any;
encodedImage:any;
encodedImage1:any;
encodedImage2:any;
encodedImage3:any;
arrayValue:any;
date:any;
paymentType:any;
sendMissingDocuments:any;
FirstName:any;
AuthKey:any;
email:any;
toCountry:any;
list:any;
listAdditional:any;
  constructor(public authService: AuthService,private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit() : void {
    this.id = this.route.snapshot.params['pkid'];
    this.userid = this.route.snapshot.params['userId'];
    this.viewapplication = new viewApplication();
    
    this.authService.getApplication(this.id)
      .subscribe(data => {
        this.list = data;
        this.viewapplication = this.list.allApplication; 
        this.FirstName = this.viewapplication.firstName;
        this.AuthKey = this.viewapplication.confirmAuthKey;
        this.email = this.viewapplication.email;
        this.toCountry = this.viewapplication.toCountry;
        var dateFormate = this.viewapplication.dateOfApplication;
        var dateOnly = dateFormate.split("T");
        this.date = dateOnly[0];
        console.log(this.date);
      }, error => console.log(error));

      this.authService.getAdditionalInfo(this.id)
      .subscribe(data => {
        this.listAdditional = data;
        this.additionalApplication = this.listAdditional.allApplication; 
      }, error => console.log(error));

      this.authService.getPaymentDetails(this.id)
      .subscribe(data => {
        console.log(data)
        this.paymentApplication = data;
        if(this.paymentApplication.paymentStatus = true){
          this.paymentType = 'Paid';
        }else{
          this.paymentType = 'Unpaid';
        }
      }, error => console.log(error));
  }

}
