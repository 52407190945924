import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditDebitMaskPipe'
})
export class CreditDebitMaskPipePipe implements PipeTransform {
  cardNo: string;
  transform(cardNumber: string, visibleDigits: number): string {
    if(cardNumber != null){
    let firstVisibleNumbers = cardNumber.slice(0, visibleDigits);
    let maskedNumbers = cardNumber.slice(visibleDigits, -visibleDigits);
    let lastVisibleNumbers = cardNumber.slice(-visibleDigits);
    this.cardNo = firstVisibleNumbers + maskedNumbers.replace(/./g, '*') + lastVisibleNumbers;
  }else{
    this.cardNo = '0';
  }
  return this.cardNo;
}

}
