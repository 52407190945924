import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

export class AuthService {
    // private apiUrl = 'http://localhost:8090/';
    private apiUrl = 'https://admin.edocs.travel/api/';
    constructor(private http: HttpClient) {}
    createEmployee(registerUser:any){
        console.log(registerUser);
        return this.http.post(this.apiUrl+"adminUser/adminsignin",registerUser,{responseType:'json'});
      }
    
      getApplicationList(){
        return this.http.get(this.apiUrl+"application/getApplicationList");
      }

      getCustomerList(){
        return this.http.get(this.apiUrl+"user/getCustomerList");
      }

      getApplication(id:any){
        return this.http.get(this.apiUrl+"application/getApplicationId/"+id);
      }
    
      getAdditionalInfo(id:any){
        return this.http.get(this.apiUrl+"addInfo/getAdditionalInfoById/"+id);
      }

      getUploadFileName(userId:any,id:any){
        return this.http.get(this.apiUrl+"addInfo/getFileNames/"+userId+"/"+id);
      }

      getApplicationPagination(input:any)
      {
        return this.http.get(this.apiUrl+"application/getPaginationApplication/" + input);
      }

      getPaymentDetails(id:any){
        return this.http.get(this.apiUrl+"payment/getPaymentByApplicationId/"+id);
      }

      getCustomerByAll(usersApplication:any){
        return this.http.post(this.apiUrl+"user/getCustomerByAllFilter",usersApplication,{responseType:'json'});
      }

      getSearchByCountry(usersApplication:any){
        return this.http.post(this.apiUrl+"application/getSearchToCountry",usersApplication,{responseType:'json'});
      }

      sendMissingDocumentsRemainder(usersApplication:any){
        return this.http.post(this.apiUrl+"application/sendMissingDocumentsRemainder",usersApplication,{responseType:'json'});
      }

      sendEvisaDownloadDocuments(usersApplication:any){
        return this.http.post(this.apiUrl+"store/sendEVisaDetailsDocumentEmail",usersApplication,{responseType:'json'});
      }
      
      storeUploaddocuments(usersApplication:any){
        return this.http.post(this.apiUrl+"store/upload",usersApplication,{responseType:'json'});
      }

      storeInsuranceUploaddocuments(usersApplication:any){
        return this.http.post(this.apiUrl+"store_insurance/uploadInsrance",usersApplication,{responseType:'json'});
      }

      getTravelInsurance(userid:any){
        return this.http.get(this.apiUrl+"insurance/getInsuranceApplicationsByUserID/"+userid);
      }

      getCheckFileName(id:any){
        return this.http.get(this.apiUrl+"store/checkFileExist/"+id);
      }

      getInsuranceCheckFileName(id:any){
        return this.http.get(this.apiUrl+"store_insurance/checkInsuranceFileExist/"+id);
      }

      getDownloadEVisaFile(userId:any,id:any){
        return this.http.get(this.apiUrl+"store/getFileForEVisa/"+userId+"/"+id);
      }

      getDownloadEVisaInsuranceFile(id:any){
        return this.http.get(this.apiUrl+"store_insurance/getFileForEVisaInsurance/"+id);
      }

      getEdocInsuranceList(){
        return this.http.get(this.apiUrl+"insurance/getEdocTodayInsuranceList");
      }

      getEdocInsuranceDetails(id:any){
        return this.http.get(this.apiUrl+"insurance/getEdocInsuranceDetails/"+id);
      }

      getEdocInsurancePaymentDetails(id:any){
        return this.http.get(this.apiUrl+"Paymentinsurance/getEdocInsurancePaymentDetails/"+id);
      }

      getInsuranceByAll(usersApplication:any){
        return this.http.post(this.apiUrl+"insurance/getInsuranceByAllFilter",usersApplication,{responseType:'json'});
      }

      getTravelInsuranceApp(id:any){
        return this.http.get(this.apiUrl+"application/getInsuranceApplicationsByApplicationID/"+id);
      }
      
      sendEvisaInsuranceDownloadDocuments(usersApplication:any){
        return this.http.post(this.apiUrl+"store_insurance/sendEvisaInsuranceDownloadDocuments",usersApplication,{responseType:'json'});
      }

      gotoStatusUpdate(usersApplication:any){
        return this.http.post(this.apiUrl+"application/StatusUpdate/",usersApplication,{responseType:'json'});
      }

      getTurkeyTravellingPriceList(){
        return this.http.get(this.apiUrl+"turkeyprice/getTurkeyTravellingPriceList")
      }

      getFromCountryList(){
        return this.http.get(this.apiUrl+"turkeyprice/getFromCountryList")
      }

      getSearchFromCountry(turkeyPriceList:any){
        return this.http.post(this.apiUrl+"turkeyprice/getSearchFromCountry",turkeyPriceList,{responseType:'json'})
      }

      updateTurkeyTravellingprice(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyprice/updateTurkeyTravellingPrice/" + param, data)
      }

      getTurkeyVisaTravelPurposeList(){
        return this.http.get(this.apiUrl+"turkeypurpose/getListTurkeyTravelPurpose")
      }

      getSearchTurkeyVisaTravelPurpose(turkeyTravelPurpose:any){
        return this.http.post(this.apiUrl+"turkeypurpose/getSearchFromCountry", turkeyTravelPurpose,{responseType:'json'})
      }

      deletePurpose(id:any){
        return this.http.delete(this.apiUrl+"turkeypurpose/deleteVisapurpose/"+id);
      }

      addTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"turkeypurpose/addTravelPurpose",usersApplication,{responseType:'json'});
      }
      
      addIndiaTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"indiapurpose/addIndiaTravelPurpose",usersApplication,{responseType:'json'});
      }

      editTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"turkeypurpose/editTravelPurpose",usersApplication,{responseType:'json'});
      }

      getIndiaTravellingPriceList(){
        return this.http.get(this.apiUrl+"indiaprice/getIndiaTravellingPriceList")
      }

      getSearchIndiaFromCountry(usersApplication:any){
        return this.http.post(this.apiUrl+"indiaprice/getSearchIndiaFromCountry",usersApplication,{responseType:'json'})
      }

      updateIndiaTravellingprice(param:any,data:any){
        return this.http.post(this.apiUrl+"indiaprice/updateIndiaTravellingPrice/" + param, data)
      }

      getIndiaVisaTravelPurposeList(){
        return this.http.get(this.apiUrl+"indiapurpose/getListIndiaTravelPurpose")
      }

      getSearchIndiaVisaTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"indiapurpose/getSearchFromCountry",usersApplication,{responseType:'json'})
      }

      deleteIndiaPurpose(id:any){
        return this.http.delete(this.apiUrl+"indiapurpose/deleteIndiaVisapurpose/"+id);
      }

      editIndiaTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"indiapurpose/editIndiaTravelPurpose",usersApplication,{responseType:'json'});
      }

      getTurkeyTravellingInsuranceList(){
        return this.http.get(this.apiUrl+"turkeyInsurance/getTurkeyInsuranceList")
      }

      updateTurkeyTravellinginsurance(usersApplication:any){
        return this.http.post(this.apiUrl+"turkeyInsurance/updateTurkeyTravellingPriceInsurance/",usersApplication,{responseType:'json'})
      }

      getEgyptTravellingPriceList(){
        return this.http.get(this.apiUrl+"egyptTravllingPrice/getEgyptPriceList")
      }

      getEgyptSearchFromCountry(usersApplication:any){
        return this.http.post(this.apiUrl+"egyptTravllingPrice/getEgyptByAllFilter",usersApplication,{responseType:'json'})
      }

      updateEgyptTravellingprice(usersApplication:any){
        return this.http.post(this.apiUrl+"egyptTravllingPrice/updateTurkeyTravellingPriceInsurance/",usersApplication,{responseType:'json'})
      }

      getMalaysiaTravellingPriceList(){
        return this.http.get(this.apiUrl+"malaysiaPurpose/getListMalaysiaTravelPurpose")
      }

      getMalaysiaSearchFromCountry(usersApplication:any){
        return this.http.post(this.apiUrl+"malaysiaPurpose/getSearchFromCountry",usersApplication,{responseType:'json'})
      }

      updateMalaysiaTravellingprice(usersApplication:any){
        return this.http.post(this.apiUrl+"malaysiaPrice/updateMalaysiaTravellingPrice/",usersApplication,{responseType:'json'})
      }

      deleteMalaysiaPurpose(id:any){
        return this.http.delete(this.apiUrl+"malaysiaPurpose/deleteVisapurpose/"+id);
      }

      addMalaysiaTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"malaysiaPurpose/addTravelPurpose",usersApplication,{responseType:'json'});
      }

      editMalaysiaTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"malaysiaPurpose/editTravelPurpose",usersApplication,{responseType:'json'});
      }

      getPakistanTravellingPriceList(){
        return this.http.get(this.apiUrl+"pakistanpurpose/getListPakistanTravelPurpose")
      }

      getPakistanSearchFromCountry(usersApplication:any){
        return this.http.post(this.apiUrl+"pakistanprice/getSearchFromCountry",usersApplication,{responseType:'json'})
      }

      updatePakistanTravellingprice(usersApplication:any){
        return this.http.post(this.apiUrl+"pakistanprice/updatePakistanTravellingPrice/",usersApplication,{responseType:'json'})
      }

      deletePakistanPurpose(id:any){
        return this.http.delete(this.apiUrl+"pakistanpurpose/deleteVisapurpose/"+id);
      }

      addPakistanTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"pakistanpurpose/addTravelPurpose",usersApplication,{responseType:'json'});
      }

      editPakistanTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"pakistanpurpose/editTravelPurpose",usersApplication,{responseType:'json'});
      }

      getUAETravellingPriceList(){
        return this.http.get(this.apiUrl+"unepurpose/getListUAETravelPurpose")
      }

      getUAESearchFromCountry(usersApplication:any){
        return this.http.post(this.apiUrl+"uneprice/getSearchFromCountry",usersApplication,{responseType:'json'})
      }

      updateUAETravellingprice(usersApplication:any){
        return this.http.post(this.apiUrl+"uneprice/updateUAETravellingPrice/",usersApplication,{responseType:'json'})
      }

      deleteUAEPurpose(id:any){
        return this.http.delete(this.apiUrl+"unepurpose/deleteVisapurpose/"+id);
      }

      addUAETravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"unepurpose/addTravelPurpose",usersApplication,{responseType:'json'});
      }

      editUAETravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"unepurpose/editTravelPurpose",usersApplication,{responseType:'json'});
      }

      getThailandTravellingPriceList(){
        return this.http.get(this.apiUrl+"thailandpurpose/getListThailandTravelPurpose")
      }

      getThailandSearchFromCountry(usersApplication:any){
        return this.http.post(this.apiUrl+"thailandprice/getSearchFromCountry",usersApplication,{responseType:'json'})
      }

      updateThailandTravellingprice(usersApplication:any){
        return this.http.post(this.apiUrl+"thailandprice/updatePakistanTravellingPrice/",usersApplication,{responseType:'json'})
      }

      deleteThailandPurpose(id:any){
        return this.http.delete(this.apiUrl+"thailandpurpose/deleteVisapurpose/"+id);
      }

      addThailandTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"thailandpurpose/addTravelPurpose",usersApplication,{responseType:'json'});
      }

      editThailandTravelPurpose(usersApplication:any){
        return this.http.post(this.apiUrl+"thailandpurpose/editTravelPurpose",usersApplication,{responseType:'json'});
      }

      deleteInsurance(id:any){
        return this.http.delete(this.apiUrl+"insurance/deleteInsurance/"+id);
      }

      gotoDeleteInsuranceFile(filepath:any){
        return this.http.post(this.apiUrl+"store_insurance/deleteInsuranceFile",filepath,{responseType:'json'});
      }

      gotoDeleteVisaFile(filepath:any){
        return this.http.post(this.apiUrl+"store/deleteVisaFile",filepath,{responseType:'json'});
      }

      deleteApplication(id:any,Country:any){
        return this.http.delete(this.apiUrl+"application/deleteApplication/"+id+"/"+Country);
      }

      updateInsuranceName(requestdata:any){
          return this.http.post(this.apiUrl+"insurance/updateInsuranceName",requestdata,{responseType:'json'})
      }

      updateInsuranceAddress(requestdata:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceAddress",requestdata,{responseType:'json'})
    }

      updateInsuranceDOB(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceDOB/" + param, data)
      }

      updateInsuranceGender(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceGender/" + param, data)
      }

      updateInsurancePhoneNo(requestdata:any){
          return this.http.post(this.apiUrl+"insurance/updateInsurancePhoneNo",requestdata,{responseType:'json'})
      }

      updateInsurancePayment(requestdata:any){
        return this.http.post(this.apiUrl+"insurance/updateInsurancePayment",requestdata,{responseType:'json'})
      }

      updateInsuranceMotherName(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceMotherName/" + param, data)
      }

      updateInsuranceDate(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceDate/" + param, data)
      }

      updateInsurancePassportNumber(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsurancePassportNumber/" + param, data)
      }

      updateInsurancePassportType(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsurancePassportType/" + param, data)
      }

      updateInsuranceInsurancePeriods(requestdata:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceInsurancePeriods",requestdata,{responseType:'json'})
      }

      updateInsuranceInsurancePrice(requestdata:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceInsurancePrice",requestdata,{responseType:'json'})
      }

      updateInsuranceInsuranceStartDate(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceInsuranceStartDate/" + param, data)
      }

      updateInsuranceEmail(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceEmail/" + param, data)
      }

      updateInsuranceFromCountry(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceFromCountry/" + param, data)
      }

      updateInsuranceFatherName(param:any,data:any){
        return this.http.post(this.apiUrl+"insurance/updateInsuranceFatherName/" + param, data)
      }

      updateApplicationFirstName(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationFirstName/" + param, data)
      }

      updateApplicationLastName(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationLastName/" + param, data)
      }

      updateApplicationPhoneNo(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationPhoneNo/" + param, data)
      }

      updateApplicationDOB(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationDOB/" + param, data)
      }

      updateApplicationNationality(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationNationality/" + param, data)
      }

      updateApplicationToCountry(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationToCountry/" + param, data)
      }

      updateApplicationPurposeForTravel(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationPurposeForTravel/" + param, data)
      }

      updateApplicationPlannedDateOfTravel(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationPlannedDateOfTravel/" + param, data)
      }

      updateApplicationTravelAirports(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationTravelAirports/" + param, data)
      }

      updateApplicationCheckApplicationStatus(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationCheckApplicationStatus/" + param, data)
      }

      updateApplicationEmail(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationEmail/" + param, data)
      }

      updateApplicationPrice(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationPrice/" + param, data)
      }

      updateApplicationDOA(param:any,data:any){
        return this.http.post(this.apiUrl+"application/updateApplicationDOA/" + param, data)
      }

      





      updateApplicationTurkeyFirstName(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyFirstName/" + param, data)
      }

      updateApplicationTurkeyLastName(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyLastName/" + param, data)
      }

      updateApplicationTurkeyPhoneNo(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyPhoneNo/" + param, data)
      }

      updateApplicationTurkeyDOB(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyDOB/" + param, data)
      }

      updateApplicationTurkeyNationality(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyNationality/" + param, data)
      }

      updateApplicationTurkeyToCountry(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyToCountry/" + param, data)
      }

      updateApplicationTurkeyPurposeForTravel(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyPurposeForTravel/" + param, data)
      }

      updateApplicationTurkeyPlannedDateOfTravel(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyPlannedDateOfTravel/" + param, data)
      }

      updateApplicationTurkeyTravelAirports(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyTravelAirports/" + param, data)
      }

      updateApplicationTurkeyCheckApplicationStatus(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyCheckApplicationStatus/" + param, data)
      }

      updateApplicationTurkeyEmail(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyEmail/" + param, data)
      }

      updateApplicationTurkeyPrice(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyPrice/" + param, data)
      }

      updateApplicationTurkeyDOA(param:any,data:any){
        return this.http.post(this.apiUrl+"turkeyApplication/updateApplicationTurkeyDOA/" + param, data)
      }




      updateApplicationEgyptFirstName(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptFirstName/" + param, data)
      }

      updateApplicationEgyptLastName(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptLastName/" + param, data)
      }

      updateApplicationEgyptPhoneNo(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptPhoneNo/" + param, data)
      }

      updateApplicationEgyptDOB(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptDOB/" + param, data)
      }

      updateApplicationEgyptNationality(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptNationality/" + param, data)
      }

      updateApplicationEgyptToCountry(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptToCountry/" + param, data)
      }

      updateApplicationEgyptPurposeForTravel(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptPurposeForTravel/" + param, data)
      }

      updateApplicationEgyptPlannedDateOfTravel(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptPlannedDateOfTravel/" + param, data)
      }

      updateApplicationEgyptTravelAirports(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptTravelAirports/" + param, data)
      }

      updateApplicationEgyptCheckApplicationStatus(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptCheckApplicationStatus/" + param, data)
      }

      updateApplicationEgyptEmail(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptEmail/" + param, data)
      }

      updateApplicationEgyptPrice(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptPrice/" + param, data)
      }

      updateApplicationEgyptDOA(param:any,data:any){
        return this.http.post(this.apiUrl+"egyptApplication/updateApplicationEgyptDOA/" + param, data)
      }
    getAuth$(): Observable<{}> {
        return of({});
    }

}