export class viewApplication {
    pkid: number;
    firstName: string;
    lastName: string;
    contactPhoneNumber: string;
    dateOfBirth: String;
    nationality: String;
    userId: String;
    // address: String;
    // dob: String;
    // usernameId:String;
    // active: boolean;
}