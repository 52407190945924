import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss']
})
export class CustomerViewComponent implements OnInit {
  userEmail:String = '';
  fullName:string = '';
  firstName:string;
  lastName:String;
  email:string;
  toCountry:string;
  checkApplicationStatus:string;
  usersApplication:any;
  usersCustomer:any;


  recordsize:any = 10;
  perPageitem:any = 10;
  page:any;
  constructor(public authService: AuthService, private router: Router) { 
    }

  ngOnInit() {
    this.authService.getCustomerList().subscribe(res => {
      this.usersCustomer = res;
    });
  }

  findUserByCustomer(obj:any){
    let data ={
      "userEmail" : this.userEmail,
      "fullName" : this.fullName,
      "recordsize" : this.recordsize,
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authService.getCustomerByAll(requestdata)
    .subscribe(
      data => {
        console.log(data);
        this.usersCustomer = data;
        this.perPageitem = this.recordsize;
      },
      error => console.log(error)); 
  }

}
