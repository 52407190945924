import { Component, Input, OnInit } from '@angular/core';
import b64toBlob from 'b64-to-blob';
import { saveAs } from 'file-saver';
@Component({
  selector: 'sb-download',
  template: `

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
     <button class="btn btn-info" (click)="save($event, encodedData, contentType, fileName)"><i class="fa fa-download"></i> &nbsp;
      <ng-content></ng-content>
    </button>
  `,
  styles: []
})
export class DownloadComponent {

  @Input() encodedData: string;
  @Input() contentType: string;
  @Input() fileName: string;

  save(
    event: Event,
    encodedData: string,
    contentType: string,
    fileName: string = 'untitled'
  ) {
    event.preventDefault();

    const blob = b64toBlob(encodedData, contentType);
    saveAs(blob, fileName);
  }

}
