import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-turkey-travelling-insurance',
  templateUrl: './turkey-travelling-insurance.component.html',
  styleUrls: ['./turkey-travelling-insurance.component.scss']
})
export class TurkeyTravellingInsuranceComponent implements OnInit {

  turkeyInsuranceList:any;
  recordsize:any=10;
  perpageItem:any=10;
  travelInsuransePrice:any;

  constructor(private authservice: AuthService, private route:ActivatedRoute)  {
  }

  ngOnInit() {
    this.authservice.getTurkeyTravellingInsuranceList().subscribe(data=> {
      this.turkeyInsuranceList = data;
    });
  }

  updateInsurancePrice(id:any,newPrice:any){
    let data ={
      "id" : id,
      "newPrice" : newPrice,
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.updateTurkeyTravellinginsurance(requestdata)
    .subscribe(data => {
      this.travelInsuransePrice = data;
      
    }, error => console.log(error));
  }

}
