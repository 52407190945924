import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'sb-view-travel-insurance',
  templateUrl: './view-travel-insurance.component.html',
  styleUrls: ['./view-travel-insurance.component.scss']
})
export class ViewTravelInsuranceComponent implements OnInit {
  id:any;
  fileSize:any;
  userid:any;
  Payment:any;
  spinnerLoader:boolean = false;
  PaymentDetails:any;
  insuranceList:any;
  insurancePayment:any;
  insuranceTravel:any;
  InsuranceDetails: boolean = true ;
  InsurancePaymentDetails: boolean = false;
  TravelInsuranceDetails: boolean = false;
  popup2: boolean = false;
  validationCheck:any;
  status:boolean = false;
  message:any;
  deleteStatus: boolean;
  AditionalError:any = "No Data Found For This Applicant....";
  statusClass = 'active';
  statusClass1 = 'not-active';
  statusClass2 = 'not-active';
  statusClassNav = 'not-active';
  statusClassNav1 = 'not-active';
  statusClassNav2 = 'not-active';
  statusClassNav3 = 'not-active';
  statusClassNav4 = 'not-active';
  statusClassNav5 = 'not-active';
  statusClassNav6 = 'not-active';
  statusClassNav7 = 'not-active';
  statusClassNav8 = 'not-active';
  statusClassNav9 = 'not-active';
  statusClassNav10 = 'not-active';
  statusClassNav11 = 'not-active';
  statusClassNav12 = 'not-active';
  statusClassNav13 = 'not-active';
  statusClassNav14 = 'not-active';
  statusClassNav15 = 'not-active';
  statusClassNav16 = 'not-active';
  statusClassNav17 = 'not-active';
  statusClassNav18 = 'not-active';
  statusClassNav19 = 'not-active';
  statusClassNav20 = 'not-active';
  isDisabled:boolean;
  filee:any;
  file:any;
  path:any;
  document:any;
  CheckFileName:any;
  checkfile:any;
  dateFile:any;
  DounloadEVisaFile:any;
  fileExtension:any;
  encodedImage4:any;
  arrayValue:any;
  FirstName:any;
  Fullname:any;
  email:any;
  sendMissingDocuments:any;
  filepath:any;
  date:any;
  getfilepath:any;
  fileId:any;
  adminVisibleId:any;
  FullAddress:any;
  insuranceStartDate:any;
  birthDate:any;
  updateStatus:boolean = false;


  firstName:any;
  Address:any;
  dob:any;
  motherName:any;
  FatherName:any
  Gender:any;
  Phoneno:any;
  insurDateOfAppln:any;
  PassportNo:any;
  PassportType:any;
  InsurancePeriod:any;
  StartDate:any;
  Email:any;
  FromCountry:any
  login_form: FormGroup;
  
  

 

  constructor(public authService: AuthService,fb: FormBuilder, private router: Router,private route: ActivatedRoute) { 
    this.login_form = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'phone': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10})$/)])],
      'firstName' : [null, Validators.compose([Validators.required])],
      'lastName' : [null, Validators.compose([Validators.required])],
      'dateOfBirth' : [null, Validators.compose([Validators.required])],
      'billingStreet' : [null, Validators.compose([Validators.required])],
      'billingCity' : [null, Validators.compose([Validators.required])],
      'billingState' : [null, Validators.compose([Validators.required])],
      'billingZipCode' : [null, Validators.compose([Validators.required])],
      'billingCountry' : [null, Validators.compose([Validators.required])],
      'FromCountry' : [null, Validators.compose([Validators.required])],
      'motherName' : [null, Validators.compose([Validators.required])],
      'fatherName' : [null, Validators.compose([Validators.required])],
      'date' : [null, Validators.compose([Validators.required])],
      'passportNumber' : [null, Validators.compose([Validators.required])],
      'passportType' : [null, Validators.compose([Validators.required])],
      'insurancePeriod' : [null, Validators.compose([Validators.required])],
      'insuranceStartDate' :  [null, Validators.compose([Validators.required])]

    });
  }

  ngOnInit() {

    this.adminVisibleId = sessionStorage.getItem("adminID");
    this.id = this.route.snapshot.params['id'];
    this.userid = this.route.snapshot.params['userId'];
    this.authService.getEdocInsuranceDetails(this.id).subscribe(res => {
      this.insuranceList = res;
      this.FirstName = this.insuranceList.firstName;
      this.Fullname = this.insuranceList.firstName +' '+this.insuranceList.lastName;
      this.FullAddress = this.insuranceList.billingStreet +' '+this.insuranceList.billingCity +' '+this.insuranceList.billingState +' '+this.insuranceList.billingZipcode +' '+this.insuranceList.billingCountry;
      this.email = this.insuranceList.email;
      this.insuranceStartDate = this.insuranceList.insuranceStartDate; 
      this.birthDate = this.insuranceList.dateOfBirth
      var dateFormate = this.insuranceList.dateOfApplication;
      var dateOnly = dateFormate.split("T");
      this.date = dateOnly[0];

      var insuranceDate = new Date(this.insuranceStartDate);
      this.insuranceStartDate = [
        insuranceDate.getFullYear(),
        ('0' + (insuranceDate.getMonth() + 1)).slice(-2),
        ('0' + insuranceDate.getDate()).slice(-2)
      ].join('-');
      console.log("d: " , insuranceDate)
      console.log("date:" ,this.insuranceStartDate)

      var dateOfBirth = new Date(this.birthDate);
      this.birthDate = [
        dateOfBirth.getFullYear(),
        ('0' + (dateOfBirth.getMonth() + 1)).slice(-2),
        ('0' + dateOfBirth.getDate()).slice(-2)
      ].join('-');
      console.log("date:" ,this.birthDate)

      console.log(this.FirstName);
      if(this.insuranceList.payment == true){
        this.Payment = 'Paid';
      }else{
        this.Payment = 'Unpaid';
      }
    });

    this.authService.getEdocInsurancePaymentDetails(this.id).subscribe(res => {
      this.insurancePayment = res;
      if(this.insurancePayment.paymentStatus == true){
        this.PaymentDetails = 'Paid';
      }else{
        this.PaymentDetails = 'Unpaid';
      }
    });

    this.authService.getInsuranceCheckFileName(this.id)
      .subscribe(data => {
        this.CheckFileName = data;
        this.checkfile = this.CheckFileName.Data.name;
        this.dateFile = this.CheckFileName.Data.date;
        this.fileId = this.CheckFileName.Data.pkid;
      }, error => console.log(error));

      this.authService.getDownloadEVisaInsuranceFile(this.id)
      .subscribe(data => {
        this.DounloadEVisaFile = data;
        var arrayKey = Object.keys(data);
        console.log(arrayKey);
        this.filepath = arrayKey;
        this.encodedImage4 = arrayKey;
        this.arrayValue = Object.values(data);
        this.fileExtension = this.arrayValue;
        
      }, error => console.log(error));
  }

  showMyApplicationPage(){
    this.router.navigate(['account']);
  }

  showMyCustomerPage(){
    this.router.navigate(['customer_view']);
  }

  showMyTravelInsurance(){
    this.router.navigate(['travel_insurance']);
  }

  gotoDeleteFile(){
    this.popup2 = true;

  }

  updateInsuranceFirstName(id:any,name:any){
    var nameArray = name;
    this.insuranceList.firstName = nameArray;
    // this.insuranceList.lastName = nameArray[1];
  }
  updateInsuranceLastName(id:any,name:any){
    var nameArray = name;
    this.insuranceList.lastName = nameArray;

  }

  updateInsuranceStreet(id:any,address:any){
    var addressArray = address; 
    this.insuranceList.billingStreet =  addressArray
    
   }
  updateInsuranceCity(id:any,address:any){
    var addressArray = address; 
    this.insuranceList.billingCity=addressArray

  }
  updateInsuranceState(id:any,address:any){
    var addressArray = address; 
    this.insuranceList.billingState=addressArray

  }
  updateInsuranceZipCode(id:any,address:any){
    var addressArray = address; 
    this.insuranceList.billingZipcode=addressArray

  }
  updateInsuranceCountry(id:any,address:any){
    var addressArray = address; 
    this.insuranceList.billingCountry=addressArray

  }

  updateInsuranceDOB(id:any,DOB:any){
    this.dob = DOB;
    this.insuranceList.dateOfBirth =  this.dob;
   
  }

  updateInsuranceGender(id:any,gender:any){
    this.Gender = gender;
    this.insuranceList.gender = this.Gender;
   
  }

  updateInsurancePhoneNo(id:any,phoneNo:any){
    this.Phoneno = phoneNo;
    this.insuranceList.phoneNo = this.Phoneno
    
  }

  updateInsurancePayment(id:any,payment:any){
    let data ={
      "id" : id,
      "paymentStatus" : payment
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authService.updateInsurancePayment(requestdata).subscribe((responce)=> {
      console.log("Responce:", responce)
    });
  }

  updateInsuranceMotherName(id:any,MotherName:any){
    this.motherName = MotherName
    this.insuranceList.motherName=this.motherName 
    
  }

  updateInsuranceFatherName(id:any,fatherName:any){
    this.FatherName = fatherName;
    this.insuranceList.fatherName=this.FatherName;

    
  }

  updateInsuranceDate(id:any,date:any){
    this.insurDateOfAppln = this.date;
    this.insuranceList.dateOfApplication = this.insurDateOfAppln
   
  }

  updateInsurancePassportNumber(id:any,passportNo:any){
    this.PassportNo = passportNo;
    this.insuranceList.passportNumber=this.PassportNo;
    
  }

  updateInsurancePassportType(id:any,passportType:any){
    this.PassportType = passportType;
    this.insuranceList.passportType = this.PassportType;
    
  }
   updateInsuranceInsurancePeriods(id:any,insuranceperiod:any){
    this.InsurancePeriod = insuranceperiod;
    this.insuranceList.insurancePeriods = this.InsurancePeriod;
  }

  updateInsuranceInsuranceStartDate(id:any,startDate:any){
    this.StartDate = startDate;
    this.insuranceList.insuranceStartDate= this.StartDate;
  }

  updateInsuranceEmail(id:any,email:any){
    this.Email = email;
    this.insuranceList.email = this.Email
  }

  updateInsuranceFromCountry(id:any,fromCountry:any){
    this.FromCountry = fromCountry;
    this.insuranceList.fromCountry =  this.FromCountry
  }

  updateData(dataList:any){
    this.id = dataList.id;

  //  Update Name
    let data ={
      "id" : this.id,
      "firstName" : this.insuranceList.firstName,
      "lastName" :this.insuranceList.lastName
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authService.updateInsuranceName(requestdata).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    // Update DOB
    this.authService.updateInsuranceDOB(this.id, this.dob).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update Gender 
    this.authService.updateInsuranceGender(this.id,this.Gender).subscribe((responce)=> {
      console.log("Responce:", responce)
    });
    //Update Address
    let data1 ={
      "id" :this.id,
      "billingStreet" : this.insuranceList.billingStreet,
      "billingCity" :  this.insuranceList.billingCity,
      "billingState" :this.insuranceList.billingState,
      "billingZipcode" : this.insuranceList.billingZipcode,
      "billingCountry" : this.insuranceList.billingCountry
    }
    let requestdata1=new FormData();
    requestdata1.append('user',JSON.stringify(data1));
    this.authService.updateInsuranceAddress(requestdata1).subscribe((responce)=> {
      console.log("Responce:", responce)
    });
    
    //Update PhoneNo
    let data2 ={
      "id" : this.id,
      "phoneNo" : this.Phoneno
    }
    let requestdata2=new FormData();
    requestdata2.append('user',JSON.stringify(data2));
    this.authService.updateInsurancePhoneNo(requestdata2).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update Mother name
    this.authService.updateInsuranceMotherName(this.id,this.motherName).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update Father Name
    this.authService.updateInsuranceFatherName(this.id, this.FatherName).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Date Of Application
    this.authService.updateInsuranceDate(this.id,this.insurDateOfAppln).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update Passport Number
    this.authService.updateInsurancePassportNumber(this.id,this.PassportNo).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update Passport Type
    this.authService.updateInsurancePassportType(this.id, this.PassportType).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update Insurance Period 
    let data3 ={
      "id" : this.id,
      "insuranceperiod" : this.InsurancePeriod
    }
    let requestdata3=new FormData();
    requestdata3.append('user',JSON.stringify(data3));
    this.authService.updateInsuranceInsurancePeriods(requestdata3).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update insurance Start Date
    this.authService.updateInsuranceInsuranceStartDate(this.id,this.StartDate).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update Email
    this.authService.updateInsuranceEmail(this.id,this.Email).subscribe((responce)=> {
      console.log("Responce:", responce)
    });

    //Update From country
    this.authService.updateInsuranceFromCountry(this.id, this.FromCountry).subscribe((responce)=> {
      console.log("Responce:", responce)
    });
    this.updateStatus = true;
    window.location.reload();

  }


  showtravelPurposePage(){
    this.router.navigate(['turkey_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showtravelPricePage(){
    this.router.navigate(['turkey_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showtravelInsurancePage(){
    this.router.navigate(['turkey_insurance']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showEgyptPricePage(){
    this.router.navigate(['egypt_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showPakistanPricePage(){
    this.router.navigate(['pakistan_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showUAEPricePage(){
    this.router.navigate(['uae_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showUAEPurposePage(){
    this.router.navigate(['uae_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }
  
  showThailandPricePage(){
    this.router.navigate(['thailand_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showThailandPurposePage(){
    this.router.navigate(['thailand_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showMalaysiaPurposePage(){
    this.router.navigate(['malaysia_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';

  }

  showIndiaPricePage(){
    this.router.navigate(['india_price']);
    this.statusClassNav = 'active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showIndiaPurposePage(){
    this.router.navigate(['india_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showMalaysiaPricePage(){
    this.router.navigate(['malaysia_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showPakistanPurposePage(){
    this.router.navigate(['pakistan_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showInsuranceDetails(){
    this.InsuranceDetails = true;
    this.InsurancePaymentDetails = false;
    this.TravelInsuranceDetails = false;
    if(this.statusClass = 'active'){
      this.statusClass1 = 'not-active';
      this.statusClass2 = 'not-active';
    }
  }

  showInsurancePaymentDetails(){
    this.InsuranceDetails = false;
    this.InsurancePaymentDetails = true;
    this.TravelInsuranceDetails = false;
    if(this.statusClass1 = 'active'){
      this.statusClass = 'not-active';
      this.statusClass2 = 'not-active';
    }
  }

  showTravelInsuranceDetails(){
    this.InsuranceDetails = false;
    this.InsurancePaymentDetails = false;
    this.TravelInsuranceDetails = true;
    if(this.statusClass2 = 'active'){
      this.statusClass = 'not-active';
      this.statusClass1 = 'not-active';
    }
  }

  deletePurpose(){
    console.log(this.filepath);
    let data ={
      "id":this.fileId,
      "filepath" : this.filepath[0],
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authService.gotoDeleteInsuranceFile(requestdata)
      .subscribe(data => {
        this.getfilepath = data;
        this.popup2 = false;
        this.deleteStatus = false;
        this.CheckFileName.Status= this.deleteStatus;
      }, error => console.log(error));
  }

  handleChange(files: FileList) {
    if (files && files.length) {
      this.filee = files[0].name;
    }
      this.file = files[0];
      let data ={
        "evisaInsuranceId":this.id,
      }
      if (!this.validateFile(files[0].name)) {
        console.log('Selected file format is not supported');
        this.validationCheck = "Selected file format is not supported (Please Select Only PNG and PDF) and File less than 2 MB.";
        return false;
      }
      if( !this.validateFileSize()){
        this.validationCheck = "Selected file format is not supported (Please Select Only PNG and PDF) and File less than 2 MB.";
        return false;
      }
      this.spinnerLoader = true;
      let requestdata = new FormData();
      requestdata.append('file', this.file);
      requestdata.append('user',JSON.stringify(data));
      this.authService.storeInsuranceUploaddocuments(requestdata).subscribe(data=>{
        console.log(data);
        this.path = data;
        if (Response) {
          this.spinnerLoader = false;
        }
        this.document= this.path.status;
        console.log(this.document);
        window.location.reload();
      },
      error =>{
        console.log(error);
        window.location.reload();
      });
      this.isDisabled= true;
    }

    
    validateFile(name: String) {
      var ext = name.substring(name.lastIndexOf('.') + 1);
      if (ext.toLowerCase() == 'png') {
          return true;
      // }else if(ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg'){
      //     return true;
      // }else if(ext.toLowerCase() == 'tiff'){
      //     return true;
      }else if(ext.toLowerCase() == 'pdf'){
          return true;
      }
      else {
          return false;
      }
    }

    validateFileSize(){
      if (this.file) {
        let fileSize = 0;
       if (this.file.size <= 1024 * 1024 || this.file.size <= 1024){
         this.fileSize = (Math.round(this.file.size * 100 / (1024 * 1024)) / 100).toString() + 'MB';
         this.fileSize = (Math.round(this.file.size * 100 / 1024) / 100).toString() + 'KB';
         return true;
       }
       else{
        return false;
       }
      }
    }
    gotoSendEvisaInsuranceDownloadDocument(){
      let data ={
        "firstName" : this.FirstName,
        "insuranceID" : this.id,
        "userID" : this.userid,
        "email" : this.email,
        "fileName" :this.checkfile,
      }
      let requestdata=new FormData();
      requestdata.append('user',JSON.stringify(data));
      this.authService.sendEvisaInsuranceDownloadDocuments(requestdata)
      .subscribe(
        data => {
          console.log(data);
          this.sendMissingDocuments = data;
          this.status= true;
          this.message = "Succefully sent document on your email...!";
        },
        error => console.log(error));
    }

    gotoProcessPagelink(){
      window.open('https://www.covidinsurance4turkey.com/Home');
    }
}