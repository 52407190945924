import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';
const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
@Component({
  selector: 'sb-travel-insurance',
  templateUrl: './travel-insurance.component.html',
  styleUrls: ['./travel-insurance.component.scss']
})
export class TravelInsuranceComponent implements OnInit {
  insuranceList:any;
  Payment:any;
  toCountry:string = '';
  toDateRecords:String = 'Today';
  firstName:string ='';
  email:string ='';
  status:string = '';
  recordsize:any = 10;
  perPageitem:any = 10;
  page:any;
  indexToScrollTo = 7;
  date:any;
  deleteinsurance:any;
  Id:any;
  popup2:boolean = false;
  ShowToDateFromDateRecords:boolean = false;
  todate:string = '';
  fromdate:string = '';
  formatedDateArray:any =[];
  popup1:boolean = false;
  paymentstatus:boolean;
  validationCheck:any;
  adminVisibleId:any;
  campaignOne = new FormGroup({
    start: new FormControl(new Date(year, month, 13)),
    end: new FormControl(new Date(year, month, 16)),
  });
  campaignTwo = new FormGroup({
    start: new FormControl(new Date(year, month, 15)),
    end: new FormControl(new Date(year, month, 19)),
  });
  constructor(public authService: AuthService, private router: Router) { }
  ngOnInit() {
    this.adminVisibleId = sessionStorage.getItem("adminID");
    this.authService.getEdocInsuranceList().subscribe(res => {
      this.insuranceList = res;
      if(this.insuranceList[0].payment == true){
        this.Payment = 'Paid';
      }else{
        this.Payment = 'Unpaid';
      }
      for (var i=0; i<this.insuranceList.length;i++){
        var dateFormate = this.insuranceList[i].dateOfApplication;
        var dateOnly = dateFormate.split("T");
        this.insuranceList[i].dateOfApplication=dateOnly[0];
        if(this.insuranceList[i].payment == true){
          this.insuranceList[i].payment = 'Paid';
        }else{
          this.insuranceList[i].payment = 'Unpaid';
        }
      }
    });
  }

  insuranceDetails(id: number, userId:number){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['viewInsuranceDetails', id, userId]));
      window.open(url, '_blank');
    // this.router.navigate(['viewInsurance', id, userId]);
  }

  insuranceDetailsEdit(id: number, userId:number){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['viewInsurance', id, userId]));
      window.open(url, '_blank');
    // this.router.navigate(['viewInsurance', id, userId]);
  }

  OpenDeletePopup(id:any){
    this.Id= id;
    this.popup2 = true;
  }
  deletePurpose(){
    this.authService.deleteInsurance(this.Id).subscribe(data=>{
      this.deleteinsurance= data;
      window.location.reload();
    })
  }
  findUserByInsurance(obj:any){
    let data ={
      "firstName" : this.firstName,
      "email" : this.email,
      "toCountry" : this.toCountry,
      "recordsize" : this.recordsize,
      "fromDate" : this.fromdate,
      "toDate" : this.todate,
      "toDateRecords" : this.toDateRecords,
      "status": this.status
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    if(this.toDateRecords == 'Custom Date' && (this.fromdate == '' || this.todate == '')){
      this.validationCheck = "Please Select From Date and To Date...!";
    }else{
    this.authService.getInsuranceByAll(requestdata)
    .subscribe(
      data => {
        console.log(data);
        this.insuranceList = data;
        this.perPageitem = this.recordsize;
        for (var i=0; i<this.insuranceList.length;i++){
          var dateFormate = this.insuranceList[i].dateOfApplication;
          var dateOnly = dateFormate.split("T");
          this.insuranceList[i].dateOfApplication=dateOnly[0];
          if(this.insuranceList[i].payment == true){
            this.insuranceList[i].payment = 'Paid';
          }else{
            this.insuranceList[i].payment = 'Unpaid';
          }
        }
      },
      error => console.log(error));
    }
  }
  tocountry = [
    {name: 'India'},
    {name: 'Turkey'},
    {name: 'Pakistan'},
    {name: 'Thailand'},
    {name: 'United-Arab-Emirates'},
    {name: 'Malasyia'}
  ]
  dateRecords = [
    {name: 'Today'},
    {name: 'Yesterday'},
    {name: 'Custom Date'}
  ]
  findByCustomDate(date:any){
    console.log(date);
    if(this.toDateRecords == "Custom Date"){
      this.ShowToDateFromDateRecords = true;
      this.popup1 = true;
    }else{
      this.ShowToDateFromDateRecords = false;
      this.popup1 = false;
      this.todate = "";
      this.fromdate = "";
    }
  }
  customDateFunction(){
    this.popup1 = false;
    if(this.toDateRecords == 'Custom Date' && (this.fromdate == '' || this.todate == '')){
      this.validationCheck = "Please Select From Date and To Date...!";
      this.popup1 = true;

    }
  }
}
