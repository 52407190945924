import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-pakistan-price',
  templateUrl: './pakistan-price.component.html',
  styleUrls: ['./pakistan-price.component.scss']
})
export class PakistanPriceComponent implements OnInit {

  pakistanPriceList:any;
  fromCountry:String = '';
  toCountry:String = '';
  selectvisatypes:String='';
  fromcountry:any;
  tocountry:any;
  recordsize:any=10;
  perpageItem:any=10;
  egyptInsuransePrice:any;

  constructor(private authservice: AuthService, private route:ActivatedRoute)  {
  }

  ngOnInit() {
    this.authservice.getPakistanTravellingPriceList().subscribe(data=> {
      this.pakistanPriceList = data;
    });

    this.authservice.getFromCountryList().subscribe(data=>{
      this.fromcountry= data;
    })
  }

  findByFromCountry(){
    let data={
      "fromCountry" : this.fromCountry,
      // "selectvisaType" : this.selectvisatypes,
      "recordSize": this.recordsize
    }
    let requestdata = new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.getPakistanSearchFromCountry(requestdata).subscribe(res=>{
      console.log(res);
        var arrayKey = Object.values(res);
        console.log(arrayKey);
        this.pakistanPriceList = arrayKey[0];
        this.perpageItem = this.recordsize;
    }, error => console.log(error));
  }

  updatePrice(id:string,newPrice:any){
    let data ={
      "id" : id,
      "newPrice" : newPrice,
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authservice.updatePakistanTravellingprice(requestdata)
    .subscribe(data => {
      this.egyptInsuransePrice = data;
      
    }, error => console.log(error));
  }
}
