export class Signup {
    id:any;
    // name:any;
    // moNumber:any;
    // email:any;
    // password:any;

    // name: string;
    // username: string;
    email: string;
    password: string;
}