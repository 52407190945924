import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationModule } from '@modules/navigation/navigation.module';
import { AccountComponent } from './account/account.component';
import { AuthService } from '@modules/auth/services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TablesModule } from '@modules/tables/tables.module';
import { ViewApplicationComponent } from './view-application/view-application.component';
import { LoginComponent } from './login/login.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccountNavComponent } from './account-nav/account-nav.component';
import { CustomerViewComponent } from './customer-view/customer-view.component';
import { DownloadComponent } from './download/download.component';
import { CreditDebitMaskPipePipe } from './pipes/credit-debit-mask-pipe.pipe';
import { ProcessPageComponent } from './process-page/process-page.component';
import { TravelInsuranceComponent } from './travel-insurance/travel-insurance.component';
import { ViewTravelInsuranceComponent } from './view-travel-insurance/view-travel-insurance.component';
import { TurkeyTravelingPriceComponent } from './turkey-traveling-price/turkey-traveling-price.component';
import { TurkeyVisaTravelPurposeComponent } from './turkey-visa-travel-purpose/turkey-visa-travel-purpose.component';
import { IndiaTravellingPriceComponent } from './india-travelling-price/india-travelling-price.component';
import { IndiaVisaTravelPurposeComponent } from './india-visa-travel-purpose/india-visa-travel-purpose.component';
import { EgyptTravellingPriceComponent } from './egypt-travelling-price/egypt-travelling-price.component';
import { TurkeyTravellingInsuranceComponent } from './turkey-travelling-insurance/turkey-travelling-insurance.component';
import { MalaysiaPriceComponent } from './malaysia-price/malaysia-price.component';
import { MalaysiaPurposeComponent } from './malaysia-purpose/malaysia-purpose.component';
import { PakistanPriceComponent } from './pakistan-price/pakistan-price.component';
import { PakistanPurposeComponent } from './pakistan-purpose/pakistan-purpose.component';
import { UnitedArabEmiratesPriceComponent } from './united-arab-emirates-price/united-arab-emirates-price.component';
import { UnitedArabEmiratesPurposeComponent } from './united-arab-emirates-purpose/united-arab-emirates-purpose.component';
import { ThailandPriceComponent } from './thailand-price/thailand-price.component';
import { ThailandPurposeComponent } from './thailand-purpose/thailand-purpose.component';
import { MatButtonModule, MatCardModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatNativeDateModule} from '@angular/material';
import { ViewTravelInsuranceDetailsComponent } from './view-travel-insurance-details/view-travel-insurance-details.component';
import { AccountEditComponent } from './account-edit/account-edit.component';

@NgModule({
    declarations: [AppComponent, AccountComponent, ViewApplicationComponent, LoginComponent, AccountNavComponent, CustomerViewComponent, DownloadComponent, 
        CreditDebitMaskPipePipe, ProcessPageComponent, TravelInsuranceComponent, ViewTravelInsuranceComponent, TurkeyTravelingPriceComponent, 
        TurkeyVisaTravelPurposeComponent, IndiaTravellingPriceComponent, IndiaVisaTravelPurposeComponent, EgyptTravellingPriceComponent,
        TurkeyTravellingInsuranceComponent,
        MalaysiaPriceComponent,
        MalaysiaPurposeComponent,
        PakistanPriceComponent,
        PakistanPurposeComponent,
        UnitedArabEmiratesPriceComponent,
        UnitedArabEmiratesPurposeComponent,
        ThailandPriceComponent,
        ThailandPurposeComponent,
        ViewTravelInsuranceDetailsComponent,
        AccountEditComponent],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule,NavigationModule,FormsModule,TablesModule,NgxPaginationModule,
        ReactiveFormsModule, MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule],
    providers: [AuthService],
    bootstrap: [AppComponent],
})
export class AppModule {}
