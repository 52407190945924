import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { viewApplication } from '@app/models/view_application';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'sb-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {
  id:any;
fileId:any;
getfilepath:any;
spinnerLoader:boolean = false;
viewapplication:any;
additionalApplication:any;
uploadApplication:any;
paymentApplication:any;
travelInsuranseApplication:any;
travelInsuranceApp:any;
status:boolean = false;
message:any;
deleteStatus: boolean;
ApplicationDetails: boolean = true ;
AdditionalInfo: boolean = false;
UploadDocument: boolean = false;
popup2: boolean = false;
Communication: boolean = false;
travelInsuranse: boolean  = false;
payment: boolean = false;
userid:any;
UploadFileNameApplication:any;
thumbnail:any;
ResidenceProofthumbnail:any;
BiographicalPassportthumbnail:any;
Photographythumbnail:any;
binaryString:any;
filePath1:any;
filePath2:any;
filePath3:any;
filePath4:any;
arrayValue:any;
date:any;
paymentType:any;
sendMissingDocuments:any;
FirstName:any;
AuthKey:any;
email:any;
toCountry:any;
filee:any;
file:any;
path:any;
document:any;
paymentstatus:any;
CheckFileName:any;
checkfile:any;
dateFile:any;
DounloadEVisaFile:any;
fileExtension:any;
validationCheck:any;
insuranceType:any;
list:any;
listAdditional:any;
listPayment:any;
fileSize:any;
AditionalError:any = "No Data Found For This Applicant....";
statusClass = 'active';
statusClass1 = 'not-active';
statusClass2 = 'not-active';
statusClass3 = 'not-active';
statusClass4 = 'not-active';
statusClass5 = 'not-active';
statusClassNav = 'not-active';
statusClassNav1 = 'not-active';
statusClassNav2 = 'not-active';
statusClassNav3 = 'not-active';
statusClassNav4 = 'not-active';
statusClassNav5 = 'not-active';
statusClassNav6 = 'not-active';
statusClassNav7 = 'not-active';
statusClassNav8 = 'not-active';
statusClassNav9 = 'not-active';
statusClassNav10 = 'not-active';
statusClassNav11 = 'not-active';
statusClassNav12 = 'not-active';
statusClassNav13 = 'not-active';
statusClassNav14 = 'not-active';
statusClassNav15 = 'not-active';
statusClassNav16 = 'not-active';
statusClassNav17 = 'not-active';
statusClassNav18 = 'not-active';
statusClassNav19 = 'not-active';
statusClassNav20 = 'not-active';
isDisabled:boolean;
filepath:any;
updateStatus:boolean = false;
adminVisibleId:any;
pkid:any;
userID:any;
firstName:any;
lastName:any;
phoneNo:any;
DOB:any;
nationality:any;
ToCountry:any;
purposeForTravel:any;
DOT:any;
Applicationstatus:any;
TravelAirports:any;
Email:any;
price:any;
DOA:any;
login_form: FormGroup;
VisaPrice:any;


  constructor(public authService: AuthService,public fb: FormBuilder, private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer) { 
    this.login_form = fb.group({
      'email': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'phone': [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10})$/)])],
      'firstName' : [null, Validators.compose([Validators.required])],
      'lastName' : [null, Validators.compose([Validators.required])],
      'dateOfBirth' : [null, Validators.compose([Validators.required])],
      'nationality' : [null, Validators.compose([Validators.required])],
      'toCountry' : [null, Validators.compose([Validators.required])],
      'purposeForTravel' : [null, Validators.compose([Validators.required])],
      'plannedDateOfTravel' : [null, Validators.compose([Validators.required])],
      'travelAirports' : [null, Validators.compose([Validators.required])],
      'visaPrice' : [null, Validators.compose([Validators.required])],
      'price' : [null, Validators.compose([Validators.required])],
      'date' : [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.adminVisibleId = sessionStorage.getItem("adminID");
    this.id = this.route.snapshot.params['pkid'];
    this.userid = this.route.snapshot.params['userId'];
    // console.log(this.id);
    this.viewapplication = new viewApplication();
    
    this.authService.getApplication(this.id)
      .subscribe(data => {
        // console.log(data)
        this.list = data;
        this.viewapplication = this.list.allApplication; 
        this.FirstName = this.viewapplication.firstName;
        this.AuthKey = this.viewapplication.confirmAuthKey;
        this.email = this.viewapplication.email;
        this.toCountry = this.viewapplication.toCountry;
        this.VisaPrice = this.viewapplication.visaPrice;
        var dateFormate = this.viewapplication.dateOfApplication;
        var dateOnly = dateFormate.split("T");
        this.date = dateOnly[0];
        console.log(this.date);
      }, error => console.log(error));

      this.authService.getAdditionalInfo(this.id)
      .subscribe(data => {
        this.listAdditional = data;
        this.additionalApplication = this.listAdditional.allApplication; 
      }, error => console.log(error));

      this.authService.getUploadFileName(this.userid,this.id)
      .subscribe(data => {
        // console.log(data)
        this.UploadFileNameApplication = data;
        var arrayKey = Object.keys(data);
        // console.log(arrayKey);
        this.arrayValue = Object.values(data);
        // console.log(this.arrayValue);
        var array = Object.entries(data);
        // console.log(array);
        this.thumbnail = this.arrayValue[0];
        this.BiographicalPassportthumbnail = this.arrayValue[1];
        this.Photographythumbnail = this.arrayValue[2];
        this.ResidenceProofthumbnail = this.arrayValue[3];

        // console.log(arrayKey[0]);
        // console.log(arrayKey[1]);
        // console.log(arrayKey[2]);
        // console.log(arrayKey[3]);
        
        // For Hotel Booking(Image display code from byte array).....
        // var hotelBooking = this.UploadFileNameApplication[0]
        // console.log(hotelBooking);
        // let objectURL = 'data:image/png;base64,' + hotelBooking;
        // this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        // console.log(this.thumbnail);
        
        this.filePath1 = arrayKey[0];
        this.filePath2 = arrayKey[1]
        this.filePath3 = arrayKey[2];
        this.filePath4 = arrayKey[3];
        // for text file read and show from byte array
        //  this.binaryString = window.atob(this.UploadFileNameApplication[0]);
        //  console.log(this.binaryString);

        //For Residence
        // var ResidenceProof = this.UploadFileNameApplication[1]
        // console.log(ResidenceProof);
        // let objectURL1 = 'data:image/png;base64,' + ResidenceProof;
        // this.ResidenceProofthumbnail = this.sanitizer.bypassSecurityTrustUrl(objectURL1);

        // //For Biographical
        // var BiographicalPassport = this.UploadFileNameApplication[2]
        // console.log(BiographicalPassport);
        // let objectURL2 = 'data:image/png;base64,' + BiographicalPassport;
        // this.BiographicalPassportthumbnail = this.sanitizer.bypassSecurityTrustUrl(objectURL2);
        
        // //For Photography
        // var Photography = this.UploadFileNameApplication[3]
        // console.log(Photography);
        // let objectURL3 = 'data:image/png;base64,' + Photography;
        // this.Photographythumbnail = this.sanitizer.bypassSecurityTrustUrl(objectURL3);
      }, error => console.log(error));


      this.authService.getDownloadEVisaFile(this.userid, this.id)
      .subscribe(data => {
        this.DounloadEVisaFile = data;
        var arrayKey = Object.keys(data);
        console.log(arrayKey);
        this.filepath = arrayKey;
        // this.filePath4 = arrayKey;
        this.arrayValue = Object.values(data);
        this.fileExtension = this.arrayValue;
        
      }, error => console.log(error));
      
      this.authService.getPaymentDetails(this.id)
      .subscribe(data => {
        console.log(data)
         this.listPayment = data;
         this.paymentApplication = this.listPayment.allApplication;
        if(this.paymentApplication.paymentStatus == true){
          this.paymentType = 'Paid';
        }else{
          this.paymentType = 'Unpaid';
        }
      }, error => console.log(error));

      this.authService.getTravelInsurance(this.userid)
      .subscribe(data => {
        // console.log(data)
        this.travelInsuranseApplication = data;
        
      }, error => console.log(error));

      this.authService.getTravelInsuranceApp(this.id)
      .subscribe(data => {
        this.travelInsuranceApp = data;
        if(this.travelInsuranceApp.insuranceSelected == true){
          this.insuranceType = 'Selected';
        }else{
          this.insuranceType = 'Unselected';
        }
      }, error => console.log(error));

      this.authService.getCheckFileName(this.id)
      .subscribe(data => {
        this.CheckFileName = data;
        this.checkfile = this.CheckFileName.Data.name;
        this.dateFile = this.CheckFileName.Data.date;
        this.fileId = this.CheckFileName.Data.pkid;
      }, error => console.log(error));
  }

  
  showMyApplicationPage(){
    this.router.navigate(['account']);
  }

  showMyCustomerPage(){
    this.router.navigate(['customer_view']);
  }

  gotoDeleteFile(){
    this.popup2 = true;
  }

  deletePurpose(){
    console.log(this.filepath);
    let data ={
      "id":this.fileId,
      "filepath" : this.filepath[0],
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authService.gotoDeleteVisaFile(requestdata)
      .subscribe(data => {
        this.getfilepath = data;
        this.popup2 = false;
        this.deleteStatus = false;
        this.CheckFileName.Status= this.deleteStatus;
      }, error => console.log(error));

  }

  showtravelPurposePage(){
    this.router.navigate(['turkey_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showtravelPricePage(){
    this.router.navigate(['turkey_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showtravelInsurancePage(){
    this.router.navigate(['turkey_insurance']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showEgyptPricePage(){
    this.router.navigate(['egypt_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showPakistanPricePage(){
    this.router.navigate(['pakistan_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showUAEPricePage(){
    this.router.navigate(['uae_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showUAEPurposePage(){
    this.router.navigate(['uae_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showThailandPricePage(){
    this.router.navigate(['thailand_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showThailandPurposePage(){
    this.router.navigate(['thailand_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }
  
  showMalaysiaPurposePage(){
    this.router.navigate(['malaysia_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';

  }

  showIndiaPricePage(){
    this.router.navigate(['india_price']);
    this.statusClassNav = 'active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showIndiaPurposePage(){
    this.router.navigate(['india_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showMalaysiaPricePage(){
    this.router.navigate(['malaysia_price']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'not-active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showPakistanPurposePage(){
    this.router.navigate(['pakistan_purpose']);
    this.statusClassNav = 'not-active';
    this.statusClassNav1 = 'not-active';
    this.statusClassNav2 = 'not-active';
    this.statusClassNav3 = 'not-active';
    this.statusClassNav4 = 'not-active';
    this.statusClassNav5 = 'not-active';
    this.statusClassNav6 = 'not-active';
    this.statusClassNav7 = 'active';
    this.statusClassNav8 = 'not-active';
    this.statusClassNav9 = 'not-active';
    this.statusClassNav10 = 'not-active';
    this.statusClassNav11 = 'not-active';
    this.statusClassNav12 = 'not-active';
    this.statusClassNav13 = 'not-active';
    this.statusClassNav14 = 'not-active';
    this.statusClassNav15 = 'not-active';
    this.statusClassNav16 = 'not-active';
    this.statusClassNav17 = 'not-active';
    this.statusClassNav18 = 'not-active';
    this.statusClassNav19 = 'not-active';
    this.statusClassNav20 = 'not-active';
  }

  showMyTravelInsurance(){
    this.router.navigate(['travel_insurance']);
  }

  showApplicationDetails(){
    this.ApplicationDetails = true;
    this.AdditionalInfo = false;
    this.UploadDocument = false;
    this.Communication = false;
    this.payment = false;
    this.travelInsuranse = false;
    if(this.statusClass = 'active'){
      this.statusClass1 = 'not-active';
      this.statusClass2 = 'not-active';
      this.statusClass3 = 'not-active';
      this.statusClass4 = 'not-active';
      this.statusClass5 = 'not-active';
    }
  }

  showAdditionalInfo(){
    this.AdditionalInfo = true;
    this.ApplicationDetails = false;
    this.UploadDocument = false;
    this.Communication = false;
    this.payment = false;
    this.travelInsuranse = false;
    if(this.statusClass1 = 'active'){
      this.statusClass = 'not-active';
      this.statusClass2 = 'not-active';
      this.statusClass3 = 'not-active';
      this.statusClass4 = 'not-active';
      this.statusClass5 = 'not-active';
    }
  }

  showUploadDeocuments(){
   this.UploadDocument = true;
   this.ApplicationDetails = false;
   this. AdditionalInfo = false;
   this.Communication = false;
   this.payment = false;
   this.travelInsuranse = false;
   if(this.statusClass2 = 'active'){
    this.statusClass1 = 'not-active';
    this.statusClass = 'not-active';
    this.statusClass3 = 'not-active';
    this.statusClass4 = 'not-active';
    this.statusClass5 = 'not-active';
  }
  }

  showCommunication(){
   this.Communication = true;
   this.ApplicationDetails = false;
   this.AdditionalInfo = false;
   this.UploadDocument = false;
   this.payment = false;
   this.travelInsuranse = false;
   if(this.statusClass4 = 'active'){
    this.statusClass1 = 'not-active';
    this.statusClass2 = 'not-active';
    this.statusClass3 = 'not-active';
    this.statusClass = 'not-active';
    this.statusClass5 = 'not-active';
  }
  }

  showPaymentDetails(){
  this.payment = true;
  this.travelInsuranse = false;
  this.ApplicationDetails = false;
   this.AdditionalInfo = false;
   this.UploadDocument = false;
   this.Communication = false;
   if(this.statusClass3 = 'active'){
    this.statusClass1 = 'not-active';
    this.statusClass2 = 'not-active';
    this.statusClass = 'not-active';
    this.statusClass4 = 'not-active';
    this.statusClass5 = 'not-active';
  }
  }

  gotoProcessPage(pkid: any, userId:any){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['process_page',pkid, userId]));
      window.open(url, '_blank');
      //  window.open('#' + url, '_blank');
      let data ={
        "applicationId" : pkid,
        "status" : "Verification Completed",
        "toCountry" :this.toCountry,
      }
      let requestdata=new FormData();
      requestdata.append('user',JSON.stringify(data));
      this.authService.gotoStatusUpdate(requestdata)
      .subscribe(data => {
        // console.log(data)
        this.travelInsuranseApplication = data;
        
      }, error => console.log(error));
  }

  showTravelInsurance(){
    this.travelInsuranse = true;
    this.payment = false;
   this.ApplicationDetails = false;
   this.AdditionalInfo = false;
   this.UploadDocument = false;
   this.Communication = false;
   if(this.statusClass5 = 'active'){
    this.statusClass1 = 'not-active';
    this.statusClass2 = 'not-active';
    this.statusClass = 'not-active';
    this.statusClass4 = 'not-active';
    this.statusClass3 = 'not-active';
  }
  }

  gotoSendMissingDocument(){
  let data ={
    "firstName" : this.FirstName,
    "applicationID" : this.id,
    "userID" : this.userid,
    "AuthKey" : this.AuthKey,
    "email" : this.email,
    "toCountry" :this.toCountry,
  }
  let requestdata=new FormData();
  requestdata.append('user',JSON.stringify(data));
  this.authService.sendMissingDocumentsRemainder(requestdata)
  .subscribe(
    data => {
      console.log(data);
      this.sendMissingDocuments = data;
      this.status= true;
      this.message = "Succefully sent document on your email...!";
    },
    error => console.log(error));
}

gotoSendEvisaDownloadDocument(){
  let data ={
    "firstName" : this.FirstName,
    "applicationID" : this.id,
    "userID" : this.userid,
    "email" : this.email,
    "fileName" :this.checkfile,
  }
  let requestdata=new FormData();
  requestdata.append('user',JSON.stringify(data));
  this.authService.sendEvisaDownloadDocuments(requestdata)
  .subscribe(
    data => {
      console.log(data);
      this.sendMissingDocuments = data;
      this.status= true;
          this.message = "Succefully sent document on your email...!";
    },
    error => console.log(error));
}

handleChange(files: FileList) {
  if (files && files.length) {
    this.filee = files[0].name;
  }
    this.file = files[0];
    let data ={
      "userId": this.userid,
      "applicationId": this.id,
    }
    if (!this.validateFile(files[0].name)) {
      console.log('Selected file format is not supported');
      this.validationCheck = "Selected file format is not supported (Please Select Only PNG and PDF) and File less than 2 MB.";
      return false;
    }
    if( !this.validateFileSize()){
      this.validationCheck = "Selected file format is not supported (Please Select Only PNG and PDF) and File less than 2 MB.";
      return false;
    }
    this.spinnerLoader = true;
    let requestdata = new FormData();
    requestdata.append('file', this.file);
    requestdata.append('user',JSON.stringify(data));
    // requestdata.append('Content-Type', 'multipart/form-data');
    this.authService.storeUploaddocuments(requestdata).subscribe(data=>{
      console.log(data);
      this.path = data;
      if (Response) {
        this.spinnerLoader = false;
      }
      this.document= this.path.status;
      console.log(this.document);
      window.location.reload();
    },
    error =>{
      console.log(error);
      window.location.reload();
    });
    this.isDisabled= true;
  }

  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'png') {
        return true;
    // }else if(ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'jpg'){
    //     return true;
    // }else if(ext.toLowerCase() == 'tiff'){
    //     return true;
    }else if(ext.toLowerCase() == 'pdf'){
        return true;
    }
    else {
        return false;
    }
  }

  validateFileSize(){
    if (this.file) {
      let fileSize = 0;
     if (this.file.size <= 1024 * 1024 || this.file.size <= 1024){
       this.fileSize = (Math.round(this.file.size * 100 / (1024 * 1024)) / 100).toString() + 'MB';
       this.fileSize = (Math.round(this.file.size * 100 / 1024) / 100).toString() + 'KB';
       return true;
     }
     else{
      return false;
     }
    }
  }

  updateApplicationID(id:any,pkid:any){
    this.pkid = pkid;
   
  }

  updateApplicationUserID(id:any,userId:any){
    this.userID = userId;
   
  }

  updateApplicationFirstName(id:any,firstName:any){
    this.firstName = firstName;
   
  }

  updateApplicationLastName(id:any,lastName:any){
    this.lastName = lastName;
   
  }

  updateApplicationPhoneNo(id:any,phoneNo:any){
    this.phoneNo = phoneNo;
   
  }

  updateApplicationDOB(id:any,DOB:any){
    this.DOB = DOB;
   
  }

  updateApplicationNationality(id:any,nationality:any){
    this.nationality = nationality;
   
  }

  updateApplicationToCountry(id:any,toCountry:any){
    this.ToCountry = toCountry;
   
  }

  updateApplicationPurposeForTravel(id:any,purposeForTravel:any){
    this.purposeForTravel = purposeForTravel;
   
  }

  updateApplicationPlannedDateOfTravel(id:any,DOT:any){
    this.DOT = DOT;
   
  }

  updateApplicationTravelAirports(id:any,TravelAirports:any){
    this.TravelAirports = TravelAirports;
   
  }

  updateApplicationCheckApplicationStatus(id:any,Applicationstatus:any){
    this.Applicationstatus = Applicationstatus;
   
  }

  updateApplicationEmail(id:any,email:any){
    this.Email = email;
   
  }
  updateApplicationPrice(id:any,price:any){
    this.price = price;
   
  }
  updateApplicationDOA(id:any,DOA:any){
    this.DOA = DOA;
   
  }

  updateData(dataList:any){
    if(this.toCountry == 'India' || this.toCountry == 'india'){
        this.id = dataList.pkid;
        
        // Update DOB
        this.authService.updateApplicationFirstName(this.id, this.firstName).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Gender 
        this.authService.updateApplicationLastName(this.id,this.lastName).subscribe((responce)=> {
          console.log("Responce:", responce)
        });
        
        this.authService.updateApplicationPhoneNo(this.id,this.phoneNo).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Mother name
        this.authService.updateApplicationDOB(this.id,this.DOB).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Father Name
        this.authService.updateApplicationNationality(this.id, this.nationality).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Date Of Application
        this.authService.updateApplicationToCountry(this.id,this.ToCountry).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Passport Number
        this.authService.updateApplicationPurposeForTravel(this.id,this.purposeForTravel).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Passport Type
        this.authService.updateApplicationPlannedDateOfTravel(this.id, this.DOT).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

      
        this.authService.updateApplicationTravelAirports(this.id, this.TravelAirports).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update insurance Start Date
        this.authService.updateApplicationCheckApplicationStatus(this.id,this.Applicationstatus).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Email
        this.authService.updateApplicationEmail(this.id,this.Email).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update From country
        this.authService.updateApplicationPrice(this.id, this.price).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        this.authService.updateApplicationDOA(this.id, this.DOA).subscribe((responce)=> {
          console.log("Responce:", responce)
        });
        
        this.updateStatus = true;
        window.location.reload();
     
    }else if(this.toCountry == 'Turkey' || this.toCountry == 'turkey'){
      this.id = dataList.pkid;
        
        // Update DOB
        this.authService.updateApplicationTurkeyFirstName(this.id, this.firstName).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Gender 
        this.authService.updateApplicationTurkeyLastName(this.id,this.lastName).subscribe((responce)=> {
          console.log("Responce:", responce)
        });
        
        this.authService.updateApplicationTurkeyPhoneNo(this.id,this.phoneNo).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Mother name
        this.authService.updateApplicationTurkeyDOB(this.id,this.DOB).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Father Name
        this.authService.updateApplicationTurkeyNationality(this.id, this.nationality).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Date Of Application
        this.authService.updateApplicationTurkeyToCountry(this.id,this.ToCountry).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Passport Number
        this.authService.updateApplicationTurkeyPurposeForTravel(this.id,this.purposeForTravel).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Passport Type
        this.authService.updateApplicationTurkeyPlannedDateOfTravel(this.id, this.DOT).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

      
        this.authService.updateApplicationTurkeyTravelAirports(this.id, this.TravelAirports).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update insurance Start Date
        this.authService.updateApplicationTurkeyCheckApplicationStatus(this.id,this.Applicationstatus).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Email
        this.authService.updateApplicationTurkeyEmail(this.id,this.Email).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update From country
        this.authService.updateApplicationTurkeyPrice(this.id, this.price).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        this.authService.updateApplicationTurkeyDOA(this.id, this.DOA).subscribe((responce)=> {
          console.log("Responce:", responce)
        });
        

        this.updateStatus = true;
        window.location.reload();
    }else if(this.toCountry == 'Egypt' || this.toCountry == 'egypt'){
      this.id = dataList.pkid;
        
        // Update DOB
        this.authService.updateApplicationEgyptFirstName(this.id, this.firstName).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Gender 
        this.authService.updateApplicationEgyptLastName(this.id,this.lastName).subscribe((responce)=> {
          console.log("Responce:", responce)
        });
        
        this.authService.updateApplicationEgyptPhoneNo(this.id,this.phoneNo).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Mother name
        this.authService.updateApplicationEgyptDOB(this.id,this.DOB).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Father Name
        this.authService.updateApplicationEgyptNationality(this.id, this.nationality).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Date Of Application
        this.authService.updateApplicationEgyptToCountry(this.id,this.ToCountry).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Passport Number
        this.authService.updateApplicationEgyptPurposeForTravel(this.id,this.purposeForTravel).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Passport Type
        this.authService.updateApplicationEgyptPlannedDateOfTravel(this.id, this.DOT).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

      
        this.authService.updateApplicationEgyptTravelAirports(this.id, this.TravelAirports).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update insurance Start Date
        this.authService.updateApplicationEgyptCheckApplicationStatus(this.id,this.Applicationstatus).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update Email
        this.authService.updateApplicationEgyptEmail(this.id,this.Email).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        //Update From country
        this.authService.updateApplicationEgyptPrice(this.id, this.price).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

        this.authService.updateApplicationEgyptDOA(this.id, this.DOA).subscribe((responce)=> {
          console.log("Responce:", responce)
        });

      this.updateStatus = true;
      window.location.reload();
    }
  }
}
