import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';
@Component({
  selector: 'sb-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  userEmail:String;
  fullName:any;
  firstName:string ='';
  lastName:String;
  email:string ='';
  toCountry:string = '';
  checkApplicationStatus:string = '';
  usersApplication:any =[];
  usersCustomer:any;
  recordsize:any = 10;
  perPageitem:any = 10;
  page:any;
  indexToScrollTo = 7;
  toDateRecords:String = 'Today';
  ShowToDateFromDateRecords:boolean = false;
  popup1:boolean = false;
  todate:string = '';
  fromdate:string = '';
  validationCheck:any;
  Id:any;
  popup2:boolean = false;
  deleteinsurance:any;
  Country:any;
  adminVisibleId:any;

  constructor(public authService: AuthService, private router: Router) {
   
  }
  ngOnInit(): void {
    this.adminVisibleId = sessionStorage.getItem("adminID");
    console.log(this.adminVisibleId);
    this.authService.getApplicationList().subscribe(res => {
      this.usersApplication = res;
      for (var i=0; i<this.usersApplication.length;i++){
        var dateFormate = this.usersApplication[i].dateOfApplication;
        var dateOnly = dateFormate.split("T");
        this.usersApplication[i].dateOfApplication=dateOnly[0];
      }
    });
  }
  

  applicationDetailsEdit(id: number, userId:number){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['accountEdit', id, userId]));
      window.open(url, '_blank');
    // this.router.navigate(['viewInsurance', id, userId]);
  }

  findUserByApplication(obj:any){
    // if(obj.toCountry !=undefined && obj.toCountry !="" && obj.recordsize !=undefined && obj.recordsize !=""){
    //   console.log(this.toCountry, this.recordsize);
    //   this.authService.getSearchByCountry(obj.toCountry, obj.recordsize).subscribe(res =>{
    //     // this.usersApplication = res;
    //     // console.log(this.usersApplication[0]);
    //     var arrayKey = Object.values(res);
    //     this.usersApplication = arrayKey[0];
    //     console.log(this.usersApplication[0]);
    //   });  
    // }else{
    let data ={
      "firstName" : this.firstName,
      "email" : this.email,
      "toCountry" : this.toCountry,
      "checkApplicationStatus" : this.checkApplicationStatus,
      "recordsize" : this.recordsize,
      "fromDate" : this.fromdate,
      "toDate" : this.todate,
      "toDateRecords" : this.toDateRecords
    }
    let requestdata=new FormData();
    requestdata.append('user',JSON.stringify(data));
    this.authService.getSearchByCountry(requestdata)
    .subscribe(
      data => {
        console.log(data);
        var arrayKey = Object.values(data);
        this.usersApplication = arrayKey;
        for (var i=0; i<this.usersApplication.length;i++){
          var dateFormate = this.usersApplication[i].dateOfApplication;
          var dateOnly = dateFormate.split("T");
          this.usersApplication[i].dateOfApplication=dateOnly[0];
        }
        this.perPageitem = this.recordsize;
      },
      error => console.log(error));
    // }
  }
  applicatioDetails(pkid: number, userId:number){
    this.router.navigate(['viewApplication', pkid, userId]);
  }
  
  tocountry = [
    {name: 'India'},
    {name: 'Turkey'},
    {name: 'Pakistan'},
    {name: 'Thailand'},
    {name: 'United-Arab-Emirates'},
    {name: 'Malasyia'},
    {name: 'Egypt'}
  ]
  checkapplicationStatus = [
    {name: 'Application received'},
    {name: 'Missing Documents'},
    {name: 'Unfinished'},
    {name: 'Unpaid'},
    {name: 'Verification Completed'}
  ]

  findByCustomDate(date:any){
    console.log(date);
    if(this.toDateRecords == "Custom Date"){
      this.ShowToDateFromDateRecords = true;
      this.popup1 = true;
    }else{
      this.ShowToDateFromDateRecords = false;
      this.popup1 = false;
      this.todate = "";
      this.fromdate = "";
    }
  }

  customDateFunction(){
    if(this.toDateRecords == 'Custom Date' && (this.fromdate == '' || this.todate == '')){
      this.validationCheck = "Please Select From Date and To Date...!";
      this.popup1 = true;
   }else{
      this.popup1 = false;
   }
  }

  OpenDeletePopup(pkid:any,toCountry:any){
    this.Id= pkid;
    this.Country = toCountry;
    this.popup2 = true;
  }

  deletePurpose(){
    this.authService.deleteApplication(this.Id,this.Country).subscribe(data=>{
      this.deleteinsurance= data;
      this.popup2 = true;
      window.location.reload();
    })
  }
}